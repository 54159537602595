<template>
  <div>
    <div class="add">
      <!-- <el-select v-model="type" placeholder="请选择订单状态" style="width:150px;margin-right: 10px;" clearable @clear="type = ''">
        <el-option v-for="item in options1" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select> -->
      <el-date-picker v-model="date" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable style="width:260px;margin-right: 10px;">
      </el-date-picker>
      <el-button @click="query()" type="primary">查询</el-button>
      <el-button type="primary" @click="exportorder">导出Excel</el-button>
    </div>
    <el-table :data="list" border style="width: 100%" id="tableBox">
      <el-table-column prop="orderId" label="订单编号"></el-table-column>
      <el-table-column prop="billNo" label="支付单号"></el-table-column>
      <el-table-column prop="receiver" label="收件人"></el-table-column>
      <el-table-column prop="tel" label="电话"></el-table-column>
      <el-table-column prop="address" label="收货地址"></el-table-column>
      <el-table-column prop="total" label="金额">
        <template slot-scope="scope"> ￥{{ scope.row.total }} </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="payTime" label="支付时间"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted(){},
  methods:{},
};
</script>